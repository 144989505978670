/**
 *
 * SoopaNova Theme Base Styles (Markdown content)
 *
 */

export default {
    root: {
        fontFamily: 'body',
        lineHeight: 'body',
        fontWeight: 'body',
        m: 0,
        p: 0
    },
    h1: {
        color: 'primary',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'black',
        fontSize: 9,
        mt: 4,
        mb: 2,
        
    },
    h2: {
        color: 'secondary',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 5,
        mt: 4,
        mb: 2,
    },
    h3: {
        color: 'primary',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 4,
    },
    h4: {
        color: 'primary',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 3,
    },
    h5: {
        color: 'primary',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 2,
    },
    h6: {
        color: 'primary',
        fontFamily: 'heading',
        lineHeight: 'heading',
        fontWeight: 'heading',
        fontSize: 1,
    },
    p: {
        color: 'text',
        fontFamily: 'body',
        fontWeight: 'fine',
        lineHeight: 'body',
    },
    ul: {
        color: 'text',
        fontFamily: 'body',
        fontWeight: 'fine',
        lineHeight: 'body',
        listStyle: 'none',
        listStylePosition: 'outside',
        margin: 0,
        padding: 0,
        'li': {
            '&::before': {
                content: '"• "',
                color: 'primary',
                mr: 2,
                ml: 1
            }
        }
    },
    ol: {
        color: 'text',
        fontFamily: 'body',
        fontWeight: 'fine',
        lineHeight: 'body',
    },
    a: {
        color: 'primary',
        textDecoration: 'none',
        '&:hover': {
            color: 'secondary'
        }
    },
    pre: {
        fontFamily: 'monospace',
        overflowX: 'auto',
        code: {
            color: 'inherit',
        },
    },
    code: {
        fontFamily: 'monospace',
        fontSize: 'inherit',
    },
    table: {
        width: '100%',
        borderCollapse: 'separate',
        borderSpacing: 0,
    },
    th: {
        textAlign: 'left',
        borderBottomStyle: 'solid',
    },
    td: {
        textAlign: 'left',
        borderBottomStyle: 'solid',
    },
    img: {
        maxWidth: '100%',
    },
    hr: {
        borderColor: 'primary'
    }
}