/**
 *
 * SoopaNova Theme Button Variants
 *
 */
import { darken } from '@theme-ui/color'

export default {
    primary: {
        color: 'background',
        fontWeight: 'fine',
        bg: 'primary',
        borderRadius: 2,
        transition: ({ durations }) =>
            `background-color ${durations[1]} ease`,
        '&:hover': {
            backgroundColor: darken('primary', 0.075),
        },
        ':focus': {
            outline: 'none',
        },
    },
    secondary: {
        variant: 'buttons.primary',
        color: 'background',
        bg: 'secondary',
        '&:hover': {
            bg: darken('secondary', 0.075),
        },
    },
    outline: {
        primary: {
            color: 'primary',
            bg: 'transparent',
            boxShadow: 'inset 0 0 0 1px',
            fontWeight: 'fine',
            transition: ({ durations }) =>
                `background-color ${durations[1]} ease`,
            '&:hover': {
                color: 'background',
            },
            ':disabled': {
                cursor: 'not-allowed',
                background: 'transparent',
                opacity: 0.4,
                color: 'text',
            },
        },
        secondary: {
            variant: 'buttons.outline.primary',
            color: 'secondary',
            '&:hover': {
                bg: 'secondary',
                color: 'background',
            },
        },
    },
    naked: {
        variant: 'buttons.outline.primary',
        boxShadow: 'none',
        // px: [2, 5],
        py: [2, 4],
        transition: ({ durations }) =>
            `background-color, boxShadow ${durations[1]} ease`,
        '&:hover': {
            background: 'transparent',
            // boxShadow: 'inset 0 0 0 1px'
        },
    },
    dropdown: {
        fontFamily: 'body',
        fontWeight: 'fine',
        cursor: 'pointer',
        display: 'flex',
        width: '100%',
        px: 1,
        py: 2,
        borderRadius: 3,
        ':hover': {
            color: 'white',
        },
        ':disabled': {
            opacity: 0.4,
            cursor: 'not-allowed',
        },
    },
}