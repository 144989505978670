/**
 *
 * SoopaNova Theme Link Variants
 *
 */

export default {
    logo: {
        textDecoration: 'none',
    },
    navigation: {
        // display: 'block',
        fontSize: 4,
        color: 'secondary',
        fontWeight: 'fine',
        fontFamily: 'body',
        transition: ({ durations }) => `color ${durations[1]} ease`,
        textDecoration: 'none',
        '&:hover': {
            color: 'highlight',
            border: 'none',
        },
    },
    footer: {
        textDecoration: 'none',
        color: 'primary',
        fontFamily: 'body',
        fontWeight: 'fine',
        px: [2, 1, 2],
        pb: 1,
        mx: 2,
        // mx: 1,
        transition: ({ durations }) => `color ${durations[1]} ease`,
        '&:hover': {
            color: 'highlight',
        },
    },
    '404': {
        variant: 'buttons.outline.primary',
    },
}