/**
 *
 * SoopaNova Theme Spinner Variants
 *
 */

export default {
    primary: {
        color: 'primary',
        strokeWidth: '2',
    },
}