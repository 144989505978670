/**
 *
 * SoopaNova Theme Text Variants
 *
 */

export default {
    heading: {
        fontFamily: 'heading',
        fontWeight: 'fine',
        background: ({ colors }) => `linear-gradient(0deg,${colors.cool},${colors.primary})`,
        color: 'primary',
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        letterSpacing: [-2, -3, -4]
    },
    body: {
        fontFamily: 'body',
        fontWeight: 'fine',
        marginBottom: 3,
    },
}