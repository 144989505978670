/**
 * SoopaNova Theme
 *
 * @export default
 */
import alerts from './alerts'
import alpha from './alpha'
import borders from './borders'
import breakpoints from './breakpoints'
import buttons from './buttons'
import colors from './colors'
import durations from './durations'
import fontSizes from './font-sizes'
import fontWeights from './font-weights'
import fonts from './fonts'
import forms from './forms'
import layout from './layout'
import lineHeights from './line-heights'
import links from './links'
import radii from './radii'
import scales from './scales'
import sizes from './sizes'
import space from './space'
import spinner from './spinner'
import styles from './styles'
import text from './text'
import zIndices from './z-indices'

export default {
    // Theme UI config
    useCustomProperties: true,
    useColorSchemeMediaQuery: true,
    initialColorModeName: 'dark',
    alerts,
    alpha,
    borders,
    breakpoints,
    buttons,
    colors,
    durations,
    fonts,
    fontSizes,
    fontWeights,
    forms,
    layout,
    lineHeights,
    links,
    radii,
    scales,
    sizes,
    space,
    spinner,
    // Base Styles (mdx content)
    styles,
    text,
    zIndices
}
