/**
 *
 * SoopaNova Theme Sizes
 *
 */

export default {
    header: {
        logo: {
            width: {
                small: '220px',
                medium: '340px',
                large: '440px',
            },
        },
        button: '36px',
    },
    portal: {
        width: ['100%', '85%', '70%'],
        minHeight: '80vh',
    },
}