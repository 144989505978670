/**
 *
 * SoopaNova Theme Form Variants
 *
 */

export default {
    label: {
        fontFamily: 'body',
        lineHeight: 'body',
        fontWeight: 'fine',
        width: 'auto',
        px: 3,
        svg: {
            marginTop: 1,
        },
    },
    input: {
        caretColor: ({ colors }) => colors.primary,
        color: 'primary',
        textAlign: 'center',
        borderColor: 'primary',
        borderWidth: 2,
        borderRadius: 2,
        p: 3,
        fontWeight: 'fine',
        backgroundColor: 'background',
        '&::placeholder': {
            color: 'primary',
            opacity: 0.6,
            fontWeight: 'fine',
        },
        ':focus': {
            outline: 'none',
        },
    },
    radio: {
        color: 'secondary',
    },
    select: {
        border: ({ borders, borderStyles, colors }) =>
            `solid ${borders[0]}px ${colors.primary}`,
        borderRadius: 2,
        fontWeight: 'fine',
        color: 'primary',
        '& + svg': {
            color: 'primary',
            transition: ({ durations }) =>
                `color ${durations[1]} ease`
        },
    }
}