/**
 *
 * SoopaNova Theme Layout
 *
 */

export default {
    container: {
        maxWidth: '1600px',
    },
}