/**
 * SoopaNova Theme Font Weights
 *
 */

export default {
    fine: 200,
    body: 400,
    heading: 600,
    bold: 700,
    black: 900,
}