/**
 * SoopaNova Theme Colors
 *
 */
export default {
    text: '#E2DBDB',
    primary: '#B088FF',
    secondary: '#6441A4',
    highlight: '#F1396D',
    cool: '#61afef',
    background: '#1C1F2E',
    muted: '#F6F6FF',
    grey: '#888888',
    black: '#333333',
}