/**
 *
 * SoopaNova Theme Alert Variants
 *
 */

export default {
    base: {
        color: 'text',
        fontFamily: 'body',
        fontWeight: 'fine',
        px: 3,
        py: 2,
        fontSize: 4,
    },
    primary: {
        variant: 'alerts.base',
        bg: 'primary',
    },
    secondary: {
        variant: 'alerts.base',
        bg: 'secondary',
    },
    error: {
        variant: 'alerts.base',
        color: 'muted',
        bg: 'error',
    },
}